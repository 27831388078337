import Layout from '@quno/patient-journey/src/components/layout/Layout';
import { useTranslation } from '@quno/patient-journey/src/hooks/useTranslation';
import styles from './NotFound.module.scss';

const NotFound = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Layout title={t('pageNotFound')} footer={null} searchIndex={false}>
      <div className={styles.page}>
        <div className={styles.container}>
          <div className={styles.wrapper}>
            <div className={styles.contentWrapper}>
              <h1 className={styles.title}>{t('pageNotFound')}</h1>
              <p className={styles.errorCode}>Error code: 404</p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default NotFound;
