import { getBrand } from '@quno/patient-journey/src/data/fetch/getBrand';
import NotFound from '@quno/patient-journey/src/components/pages/NotFound';
import type { GetStaticProps } from 'next';

const page404 = (): JSX.Element => {
  return <NotFound />;
};

export const getStaticProps: GetStaticProps<
  Record<string, unknown>,
  never
> = async ({ locale }) => {
  const defaultLocale = locale === 'default' ? 'en' : locale;

  const brand = await getBrand(process.env.BRAND_ID || null, defaultLocale);

  return {
    props: {
      brand,
    },
  };
};

export default page404;
